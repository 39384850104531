<template>
  <div>
    <b-custom-tabs :tabs="tabs" lazy> </b-custom-tabs>
  </div>
</template>

<script>
import CompanyAssignmentsToUser from "./CompanyAssignmentsToUser.vue";
import AdminInfo from "./AdminInfo.vue";
import AdminLoginLogs from "./AdminLoginLogs.vue";

export default {
  computed: {
    tabs() {
      return [
        { key: "general", title: "Genel Bilgiler", component: AdminInfo },
        ...(this?.selectedUser?.role?.key === "superAdmin" ? [] : [{ key: "assignmets", title: "Atamalar", component: CompanyAssignmentsToUser }]),
        { key: "loginLogs", title: "Girişler", component: AdminLoginLogs },
      ];
    },
    selectedUser() {
      return this.$store.getters?.appModalItem?.data;
    },
  },
};
</script>

<style></style>
