<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="companyAssignmentForm">
      <app-select-input
        v-model="selected"
        name="Firma"
        :options="options"
        placeholder="Firma Seçiniz..."
        label="Firma:"
        select_label="name"
        multiple
        :reduce="reduceData"
        rules="required"
      />
      <app-button @click="makeAssignment" text="Atama Yap" block size="md" />
      <hr />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
export default {
  components: { ValidationObserver },
  data() {
    return {
      options: [],
      selected: [],
    };
  },
  computed: {
    selectedUser() {
      return this.$store.getters?.appModalItem?.data;
    },
    callback() {
      return this.$store.getters?.appModalSidebarItem?.callback;
    },
  },
  methods: {
    getOptions() {
      this.$axios
        .get("/finance/parasut-companies/options", { params: { user: this.selectedUser?._id }, loading: "sidebar" })
        .then((result) => {
          this.options = result.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    reduceData(option) {
      return {
        parasutCompany: option?._id,
        companyName: option?.name,
        contact_id: option?.contact_id,
      };
    },
    makeAssignment() {
      this.$validate(this.$refs.companyAssignmentForm, () => {
        this.$axios
          .post(
            "/finance/parasut-companies/assignments",
            {
              assignedTo: this.selectedUser?._id,
              selected: this.selected,
            },
            { loading: "sidebar" }
          )
          .then(() => {
            this.callback && this.callback();
            this.$emitter?.$emit("Notification", { title: "İşlem Başarılı", variant: "success" });
            this.$store.commit("cleanAppModalSidebar");
          })
          .catch((err) => {
            this.$emitter?.$emit("Notification", { title: "Hata Oluştu", variant: "danger", message: err?.data?.message });
          });
      });
    },
  },
  mounted() {
    this.getOptions();
  },
};
</script>

<style></style>
