<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="newAdminForm">
      <app-input v-model="auth.fullname" type="text" name="Ad Soyad" label="Ad Soyad:" placeholder="Ad Soyad" rules="required" />
      <app-input v-model="auth.phone" type="phone" name="Telefon" label="Telefon:" placeholder="Telefon" rules="required|min:13" />
      <app-input v-model="auth.email" type="email" name="E-Posta" label="E-Posta:" placeholder="E-Posta" rules="required|email" />
      <app-select-input
        v-model="auth.role"
        name="Rol"
        :options="roles"
        select_label="value"
        label="Rol:"
        placeholder="Rol Seçiniz..."
        rules="required"
        :reduce="(option) => option._id"
        :loading="isRoleOptionLoading"
      />
      <app-button @click="updateItem ? updateAdmin() : createAdmin()" :text="updateItem ? 'Güncelle' : 'Ekle'" :icon="updateItem ? 'EditIcon' : 'PlusIcon'" size="md" block />
      <hr />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
export default {
  components: {
    ValidationObserver,
  },
  data() {
    return {
      auth: {
        fullname: null,
        phone: null,
        email: null,
        role: null,
      },
      roles: [],
      isRoleOptionLoading: false,
    };
  },
  computed: {
    updateItem() {
      return this.$store.getters?.appSidebarItem?.updateItem;
    },
    callback() {
      return this.$store.getters?.appSidebarItem?.callback;
    },
  },
  methods: {
    createAdmin() {
      this.$validate(this.$refs.newAdminForm, () => {
        this.$axios
          .post("/administrators/new-user", { ...this.auth, phone: this.auth.phone.replaceAll(" ", "") }, { loading: "sidebar" })
          .then(() => {
            this.$emitter.$emit("Notification", { title: "İşlem Başarılı", variant: "success", message: "Kullanıcı başarıyla eklendi" });
            this.callback && this.callback();
            this.$store.commit("cleanAppSidebar");
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { title: "Hata Oluştu", variant: "danger", message: err?.data?.message ?? null });
          });
      });
    },
    updateAdmin() {
      this.$validate(this.$refs.newAdminForm, () => {
        this.$axios
          .patch(`/administrators/update-user/${this?.updateItem?._id}`, { ...this.auth, phone: this.auth.phone.replaceAll(" ", "") }, { loading: "sidebar" })
          .then(() => {
            this.$emitter.$emit("Notification", { title: "İşlem Başarılı", variant: "success", message: "Kullanıcı başarıyla güncellendi." });
            this.callback && this.callback();
            this.$store.commit("cleanAppSidebar");
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { title: "Hata Oluştu", variant: "danger", message: err?.data?.message ?? null });
          });
      });
    },
    getRoles() {
      this.isRoleOptionLoading = true;
      this.$axios
        .get("/permissions/roles", { params: { group: "administrator" } })
        .then((res) => {
          this.roles = res.data;
          this.isRoleOptionLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isRoleOptionLoading = false;
        });
    },
  },
  mounted() {
    this.getRoles();
    if (this?.updateItem) {
      ["fullname", "phone", "email"].map((key) => (this.auth[key] = this?.updateItem[key]));
      this.auth.role = this?.updateItem?.role?._id;
    }
  },
};
</script>

<style></style>
