<template>
  <app-overlay>
    <db-searchable-table v-model="filters" @row-clicked="showDetails" @changed="getData" class="border" :items="items" :total-rows="total" :fields="fields" searchable hover>
      <template #modalbutton>
        <app-button @click="showForm" text="Kullanıcı Ekle" icon="PlusIcon" size="md" />
      </template>
      <template #actions="{ item }">
        <div class="d-flex align-items-center">
          <app-dropdown class="pr-50">
            <b-dropdown-item @click="showUpdateForm(item)"> <feather-icon class="mr-25" icon="EditIcon" /> Kullanıcı Güncelle </b-dropdown-item>
            <b-dropdown-item @click="renewPassword(item)"> <feather-icon class="mr-25" icon="RefreshCcwIcon" /> Şifre Yenile </b-dropdown-item>
            <b-dropdown-item @click="deleteUser(item)" v-if="item._id !== user._id"><feather-icon class="mr-25" icon="TrashIcon" /> Kullanıcıyı Sil </b-dropdown-item>
          </app-dropdown>
          <app-switch
            @change="chageAllowed(item, $event)"
            v-model="item.allowed"
            class="border-left pl-50"
            v-if="item && item.role && item.role.key !== 'superAdmin'"
            v-b-tooltip.hover.bottom="'Panel Kullanımı'"
          />
        </div>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import UserDetails from "./Details/UserDetails.vue";
import NewAdminForm from "./NewAdminForm.vue";

export default {
  data() {
    return {
      fields: [
        { key: "fullname", label: "İsim" },
        { key: "phone", label: "Telefon" },
        { key: "email", label: "E-Posta" },
        { key: "role.value", label: "Unvan" },
        { key: "actions", label: "İşlemler" },
      ],
      filters: {},
      items: [],
      total: 0,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    getData() {
      this.$axios
        .get("/administrators", { loading: "table" })
        .then((result) => {
          this.items = result?.data?.data;
          this.total = result?.data?.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showForm() {
      this.$showAppSidebar("Kullanıcı Ekle", NewAdminForm, { callback: this.getData });
    },
    showUpdateForm(item) {
      this.$showAppSidebar("Kullanıcı Güncelle", NewAdminForm, { updateItem: item, callback: this.getData });
    },
    chageAllowed(item, val) {
      this.$axios
        .patch(`/administrators/update-allowed/${item?._id}`, { allowed: val }, { loading: "table" })
        .then(() => {
          this.$emitter.$emit("Notification", { variant: "success" });
        })
        .catch((err) => {
          this.$emitter.$emit("Notification", { variant: "danger", message: err?.data?.message });
        });
    },
    showDetails(item) {
      this.$showAppModal({
        title: item?.fullname ?? "Detaylar",
        component: UserDetails,
        size: "xl",
        centered: false,
        item: { data: { ...item }, callback: this.getData },
      });
    },
    renewPassword(item) {
      this.$confirm({ message: "Kullanıcı şifresi sıfırlanacak emin misiniz?" }, () => {
        this.$axios
          .patch(`/administrators/renew-password/${item._id}`, {}, { loading: "table" })
          .then((result) => {
            this.$emitter.$emit("Notification", { title: "İşlem Başarılı", message: result?.data?.message, variant: "success" });
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { title: "Hata Oluştu", message: err?.data?.message, variant: "danger" });
          });
      });
    },
    deleteUser(item) {
      this.$confirm({ message: "Kullanıcı silinecek emin misiniz?" }, () => {
        this.$axios
          .delete(`/administrators/delete-user/${item._id}`, {}, { loading: "table" })
          .then((result) => {
            this.getData();
            this.$emitter.$emit("Notification", { title: "İşlem Başarılı", message: result?.data?.message, variant: "success" });
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { title: "Hata Oluştu", message: err?.data?.message, variant: "danger" });
          });
      });
    },
  },
};
</script>

<style></style>
