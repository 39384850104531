<template>
  <b-row>
    <b-col lg="6">
      <b-card body-class="p-50">
        <app-detail-table :data="selectedUser" :fields="fields">
          <template #allowed="item">
            <app-switch @change="chageAllowed(item, $event)" v-model="item.allowed" />
          </template>
        </app-detail-table>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  computed: {
    fields() {
      return [
        { key: "fullname", label: "İsim" },
        { key: "role.value", label: "Rol" },
        { key: "email", label: "E-Posta" },
        { key: "phone", label: "Telefon" },
        ...(this?.selectedUser?.role?.key === "superAdmin" ? [] : [{ key: "allowed", label: "Panel Kullanımı" }]),
      ];
    },
    selectedUser() {
      return this.$store.getters?.appModalItem?.data;
    },
    callback() {
      return this.$store.getters?.appModalItem?.callback;
    },
  },
  methods: {
    chageAllowed(item, val) {
      this.$axios
        .patch(`/administrators/update-allowed/${item?._id}`, { allowed: val }, { loading: "table" })
        .then(() => {
          this.$emitter.$emit("Notification", { variant: "success" });
          this.callback && this.callback();
        })
        .catch((err) => {
          this.$emitter.$emit("Notification", { variant: "danger", message: err?.data?.message });
        });
    },
  },
};
</script>
