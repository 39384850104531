<template>
  <app-overlay loading-variable="modalOverlay">
    <db-searchable-table v-model="filters" @changed="getData" :items="items" :fields="fields" :total-rows="total" searchable>
      <template #modalbutton>
        <app-button @click="showForm" text="Atama Yap" icon="PlusIcon" size="md" />
      </template>
      <template #actions="{ item }">
        <app-icon-button @click="deleteAssignment(item)" icon="TrashIcon" variant="gradient-primary" />
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import CompanyAssignmentForm from "./CompanyAssignmentForm.vue";
export default {
  data() {
    return {
      filters: {},
      items: [],
      total: 0,
      fields: [
        { key: "companyName", label: "Firma Adı" },
        { key: "actions", label: "Eylemler" },
      ],
    };
  },
  computed: {
    selectedUser() {
      return this.$store.getters?.appModalItem?.data;
    },
  },
  methods: {
    showForm() {
      this.$showAppModalSidebar("Atama Yap", CompanyAssignmentForm, { callback: this.getData });
    },
    getData() {
      this.$axios
        .get("/finance/parasut-companies/assignments/list", { params: { ...this.filters, assignedTo: this.selectedUser?._id }, loading: "modal" })
        .then((result) => {
          this.items = result.data?.data;
          this.total = result?.data?.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteAssignment(item) {
      this.$confirm({ message: "Atama silinecek emin misiniz?" }, () => {
        this.$axios
          .delete(`/finance/parasut-companies/assignments/${item?._id}`)
          .then(() => {
            this.$emitter?.$emit("Notification", { title: "İşlem Başarılı", variant: "success" });
            this.getData();
          })
          .catch((err) => {
            this.$emitter?.$emit("Notification", { title: "Hata Oluştu", variant: "danger", message: err?.data?.message });
          });
      });
    },
  },
};
</script>

<style></style>
